import { useState, useEffect, useCallback, useRef } from 'react'
import { LayoutProps } from '@sport1/types/web'
import { useRouter } from 'next/router'
import {
    getLayout,
    getLayoutByModeAndSlug,
    getLayoutByTag,
    getTagBySlug,
} from '@/pages/api/cms/content/tag/[id]'
import { getEntryTo } from '@/utils/urlTranslator'
import { useAdPlacementContext } from '@/context/AdPlacementContext/AdPlacementProvider'
import Config from '@/utils/Config'

type LayoutPaginationProps = {
    layoutData: LayoutProps
    initialPage?: number
    isPlaylistPage?: boolean
}

type ReturnValue = {
    layout: LayoutProps
}

const useMediathekPaging = ({
    layoutData,
    initialPage = 0,
    isPlaylistPage,
}: LayoutPaginationProps): ReturnValue => {
    const { asPath, query } = useRouter()
    const currentPage = useRef(initialPage)
    const isFetchingNextPage = useRef(false)
    const slugArray = (query.slug as string[]) || []
    const slug = `/${slugArray.join('/')}`
    const [hasNextPage, setHasNextPage] = useState(!!slug)
    const doesLinkContainShows = slug.includes('shows')

    let requestPath = slug

    if (doesLinkContainShows) {
        requestPath = `/tv-video${slug}`
    }

    const [currentLayoutData, setCurrentLayoutData] = useState(layoutData || { components: [] })
    const { enrichAdsWithId } = useAdPlacementContext()

    const loadNextPage = useCallback(() => {
        if (!slug || isFetchingNextPage.current) return

        isFetchingNextPage.current = true
        currentPage.current += 1

        Promise.all([
            getTagBySlug(
                doesLinkContainShows || asPath === '/tv-video' ? '/tv-video' : requestPath
            ),
            getEntryTo(requestPath),
        ]).then(([tag, layoutUrl]) => {
            let fetchLayout

            if (doesLinkContainShows) {
                fetchLayout = getLayout(`${Config.CMS_API}${layoutUrl}`, currentPage.current)
            } else {
                fetchLayout = isPlaylistPage
                    ? getLayoutByModeAndSlug(
                          slug,
                          slug.includes('highlights') ? 'HIGHLIGHTS' : 'VIDEO',
                          currentPage.current
                      )
                    : getLayoutByTag(tag, currentPage.current)
            }

            fetchLayout
                .then(layout => {
                    if (layout && layout.components.length > 0) {
                        enrichAdsWithId(layout.components)
                        setCurrentLayoutData(prev => ({
                            ...prev,
                            components: [...prev.components, ...layout.components],
                        }))
                    } else {
                        setHasNextPage(false)
                    }
                    isFetchingNextPage.current = false
                })
                .catch(() => {
                    setHasNextPage(false)
                    isFetchingNextPage.current = false
                })
        })
    }, [asPath, enrichAdsWithId, slug, doesLinkContainShows, isPlaylistPage, requestPath])

    useEffect(() => {
        const handleScroll = () => {
            if (
                hasNextPage &&
                window.innerHeight + window.scrollY >= document.body.offsetHeight - 1200 &&
                !isFetchingNextPage.current
            ) {
                loadNextPage()
            }
        }

        window.addEventListener('scroll', handleScroll)
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [hasNextPage, loadNextPage])

    return { layout: currentLayoutData }
}

export default useMediathekPaging
