import React, { FC } from 'react'
import { GetServerSideProps } from 'next'
import { LayoutProps, TagNavigationItemProps } from '@sport1/types/web'
import { getLayout, getLayoutByTag, getTagBySlug } from '../api/cms/content/tag/[id]'
import { getSport1Navigation } from '../api/cms/navigation'
import { NavigationData } from '@/utils/navigation/Standard/types/NavigationItem'
import StandardLayout from '@/layouts/Standard'
import PageContentLayout from '@/layouts/Standard/PageContentLayout'
import { getEntryTo } from '@/utils/urlTranslator'
import Config from '@/utils/Config'
import useMediathekPaging from '@/hooks/useMediathekPaging'
import extendMediathek from '@/utils/seo/extendMediathek'
import extendMediathekChannel from '@/utils/seo/extendMediathekChannel'
import handleRejection from '@/utils/errors/handleRejection'

type Props = {
    layoutData: LayoutProps
    navigation?: NavigationData
}

const TVVideo: FC<Props> = ({ layoutData, navigation }) => {
    const { layout } = useMediathekPaging({
        layoutData,
    })

    return (
        <StandardLayout navigation={navigation} mainContenMaxWidth={1572}>
            <PageContentLayout layoutData={layout} />
        </StandardLayout>
    )
}

export const getServerSideProps: GetServerSideProps<Props> = async ctx => {
    const slugs = (ctx.query.slug || []) as string[]
    let navigationData, tag
    try {
        ;[navigationData, tag] = await Promise.all([
            getSport1Navigation(),
            getTagBySlug('/tv-video'),
        ])
    } catch (error) {
        return handleRejection(error, { slugs })
    }

    // TODO: What should we do when two layout urls should map to the same url. For now we hardcode the href by ourselves
    let layoutData: LayoutProps
    let channel: TagNavigationItemProps | undefined
    let deeplink = ''
    if (slugs.length === 0) {
        try {
            layoutData = await getLayoutByTag(tag)
        } catch (error) {
            return handleRejection(error, { slugs })
        }
        deeplink = `sport1://tag/${tag.contextId}`
        ctx.res.setHeader('deeplink', deeplink)
        extendMediathek(layoutData)
    } else {
        let layoutUrl
        try {
            layoutUrl = await getEntryTo(`/tv-video/${slugs.join('/')}`)
            layoutData = await getLayout(`${Config.CMS_API}${layoutUrl}`)
        } catch (error) {
            return handleRejection(error, { slugs })
        }
        channel = tag.navigationItems?.find(item => item.href === ctx.resolvedUrl)
        extendMediathekChannel(layoutData, channel?.title)
    }
    if (tag.navigationItems) {
        tag.navigationItems[0].href = '/tv-video'
    }

    return {
        props: {
            navigation: {
                ...navigationData,
                tag,
                breadCrumbs: { pageTitle: channel?.title || '' },
            },
            layoutData,
            deeplink,
        },
    }
}

export default TVVideo
